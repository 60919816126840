export interface ILang {
	[key: string]: {
		text: string;
		lng: 'id-ID' | 'en-US';
		icon: string;
	};
}

const LANG: ILang = {
	ID: {
		text: 'Indonesia',
		lng: 'id-ID',
		icon: 'CustomId',
	},
	EN: {
		text: 'English',
		lng: 'en-US',
		icon: 'CustomUsa',
	},
};

export const getLangWithKey = (key: ILang['key']['lng']): ILang['key'] => {
	// @ts-ignore
	return LANG[Object.keys(LANG).filter((f) => LANG[f].lng === key)];
};

export default LANG;

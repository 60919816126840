import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

interface IConfirm {
	title: string;
	message: string;
	onConfirm: () => void;
	onCancel?: () => void;
}

const confirm = ({ title, message, onConfirm, onCancel }: IConfirm) => {
	confirmAlert({
		title: title,
		message: message,
		buttons: [
			{ label: 'Tidak', onClick: onCancel },
			{
				label: 'Ya',
				onClick: onConfirm,
			},
		],
	});
};

export default confirm;

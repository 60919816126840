import React, { lazy, useContext } from 'react';
import { Route, Routes } from 'react-router-dom';
import AuthContext from '../../contexts/authContext';
import getRoutes from '../../routes';

const PAGE_NOT_FOUND = lazy(() => import('../../pages/error/not-found'));
const ContentRoutes = () => {
	const { userData } = useContext(AuthContext);
	const routes = getRoutes(userData.role?.id!);

	return (
		<Routes>
			{routes.map((page) => (
				// eslint-disable-next-line react/jsx-props-no-spreading
				<Route key={page.path} {...page} />
			))}
			<Route path='*' element={<PAGE_NOT_FOUND />} />
		</Routes>
	);
};

export default ContentRoutes;

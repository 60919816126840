import BaseService from './base';
import { IPagination } from '../interfaces/pagination';
import { IAgronomis } from '../interfaces/agronomis';

class AgronomisService extends BaseService {
	async create(data: any) {
		try {
			const response = await this.instance.post('/agronomis/', data);
			return await Promise.resolve(response.data);
		} catch (error: any) {
			return Promise.reject(error.response.data);
		}
	}

	async getAll(params: IPagination) {
		try {
			const response = await this.instance.get(
				`/agronomis?page=${params.page}&limit=${params.limit}&field=${params.field}&search=${params.search}`,
			);
			return await Promise.resolve(response.data);
		} catch (error: any) {
			return Promise.reject(error.response.data);
		}
	}

	async getById(id: string): Promise<IAgronomis> {
		try {
			const response = await this.instance.get(`/agronomis/${id}`);
			return await Promise.resolve(response.data);
		} catch (error: any) {
			return Promise.reject(error.response.data);
		}
	}

	async update(id: string, data: any) {
		try {
			const response = await this.instance.put(`/agronomis/${id}`, data);
			return await Promise.resolve(response.data);
		} catch (error: any) {
			return Promise.reject(error.response.data);
		}
	}

	async updatePasword(id: string, data: any) {
		try {
			const response = await this.instance.put(`/agronomis/update-password/${id}`, data);
			return await Promise.resolve(response.data);
		} catch (error: any) {
			return Promise.reject(error.response.data);
		}
	}
}

export default AgronomisService;

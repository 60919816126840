import React, { SVGProps } from 'react';

const SvgCustomId = (props: SVGProps<SVGSVGElement>) => (
    <svg
		xmlns='http://www.w3.org/2000/svg'
		viewBox='0 0 512 512'
		xmlSpace='preserve'
		width='1em'
		height='1em'
	  	className='svg-icon'
	  	{...props}
    >
      <g transform="translate(0 -308.268)">
        <path
          fill="red"
          fillRule="evenodd"
          stroke="#000"
          strokeLinecap="butt"
          strokeLinejoin="miter"
          strokeOpacity="1"
          strokeWidth="0.879"
          d="M3.272 313.828H1056.451V687.184H3.272z"
        ></path>
        <path
          fill="#f4d7d7"
          fillRule="evenodd"
          stroke="#000"
          strokeLinecap="butt"
          strokeLinejoin="miter"
          strokeOpacity="1"
          strokeWidth="0.879"
          d="M4.605 687.068H1057.784V1060.424H4.605z"
        ></path>
        <path
          fill="#fff"
          fillOpacity="1"
          d="M6.498 565.075V379.79h1050.391v370.57H6.498V565.075z"
          transform="translate(0 308.268)"
        ></path>
      </g>
    </svg>
  );

export default SvgCustomId;

window.OneSignal = window.OneSignal || [];

export async function init() {
	await window.OneSignal.init({
		appId: process.env.REACT_APP_ONESIGNAL_APP_ID!,
		notifyButton: {
			enable: true,
		},
		promptOptions: {
			slidedown: {
				enabled: true,
				autoPrompt: true,
				timeDelay: 20,
				pageViews: 3,
				actionMessage:
					"We'd like to show you notifications for the latest news and updates.",
				acceptButtonText: 'IZINKAN',
				cancelButtonText: 'BLOCK',
			},
		},
	});
}

export function setUserId(userId: string) {
	window.OneSignal.isPushNotificationsEnabled(async function (isEnabled: boolean) {
		if (isEnabled) {
			const externalUserId = await window.OneSignal.getExternalUserId();
			console.log({ userId, externalUserId });
			if (userId !== externalUserId) {
				window.OneSignal.setExternalUserId(userId);
			}
		}
	});
}

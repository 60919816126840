import moment from 'moment';
import 'moment/locale/id';
moment.locale('id');

// open land -25 day from planting
export function openLand(planting?: Date): string {
	if (!planting) return '';
	const result = moment(planting).subtract(25, 'days').format('YYYY-MM-DD');
	return result;
}

// spread seed -18 day from planting
export function spreadSeed(planting?: Date): string {
	if (!planting) return '';
	const result = moment(planting).subtract(18, 'days').format('YYYY-MM-DD');
	return result;
}

// harvest +100 day from planting
export function harvest(planting?: Date): string {
	if (!planting) return '';
	const result = moment(planting).add(100, 'days').format('YYYY-MM-DD');
	return result;
}

import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { DropdownItem, DropdownMenu } from '../../components/bootstrap/Dropdown';
import Button from '../../components/bootstrap/Button';
import Collapse from '../../components/bootstrap/Collapse';
import { NavigationLine } from '../Navigation/Navigation';
import Icon from '../../components/icon/Icon';
import AuthContext from '../../contexts/authContext';
import Spinner from '../../components/bootstrap/Spinner';
import AuthService from '../../services/auth';
import storage from '../../utils/storage';

const User = () => {
	const authService = new AuthService();
	const { userData, setUser, setIsLogin } = useContext(AuthContext);

	const [collapseStatus, setCollapseStatus] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(false);

	const { t } = useTranslation(['translation', 'menu']);

	return (
		<>
			<div
				className={classNames('user', { open: collapseStatus })}
				role='presentation'
				onClick={() => setCollapseStatus(!collapseStatus)}>
				<div className='user-avatar'>
					<img
						src={`${process.env.REACT_APP_ASSET_BASE_URL}${userData?.user_profile?.photo}`}
						alt='Avatar'
						width={128}
						height={128}
						onError={(error) => {
							error.currentTarget.src = require('../../assets/img/wanna/wanna1.png');
						}}
					/>
				</div>
				<div className='user-info'>
					<div className='user-name d-flex align-items-center'>
						{`${userData?.user_profile?.firstname} ${userData?.user_profile?.lastname}`}
						<Icon icon='Verified' className='ms-1' color='info' />
					</div>
					<div className='user-sub-title'>{userData?.user_profile?.city?.label}</div>
				</div>
			</div>
			<DropdownMenu>
				<DropdownItem>
					<Button icon='AccountBox' onClick={() => {}}>
						Profile
					</Button>
				</DropdownItem>
			</DropdownMenu>

			<Collapse isOpen={collapseStatus} className='user-menu'>
				<nav aria-label='aside-bottom-user-menu'>
					<div className='navigation'>
						<div
							role='presentation'
							className='navigation-item cursor-pointer'
							onClick={() => {}}>
							<span className='navigation-link navigation-link-pill'>
								<span className='navigation-link-info'>
									<Icon icon='AccountBox' className='navigation-icon' />
									<span className='navigation-text'>{t('menu:Profile')}</span>
								</span>
							</span>
						</div>
					</div>
				</nav>
				<NavigationLine />
				<nav aria-label='aside-bottom-user-menu-2'>
					<div className='navigation'>
						<div
							role='presentation'
							className='navigation-item cursor-pointer'
							onClick={async () => {
								setLoading(!loading);
								await authService
									.signout()
									.then(() => {
										setIsLogin(false);
										setLoading(!loading);
										setUser('');
										storage.remove('user');
										storage.remove('isLogin');
									})
									.catch(() => {
										setIsLogin(false);
										setLoading(!loading);
										setUser('');
										storage.remove('user');
										storage.remove('isLogin');
									});
							}}>
							<span className='navigation-link navigation-link-pill'>
								<span className='navigation-link-info'>
									<Icon icon='Logout' className='navigation-icon' />
									<span className='navigation-text'>{t('menu:Logout')}</span>
									{'  '} {loading && <Spinner isSmall inButton isGrow />}
								</span>
							</span>
						</div>
					</div>
				</nav>
			</Collapse>
		</>
	);
};

export default User;

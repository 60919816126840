import moment from 'moment';
import 'moment/locale/id';
moment.locale('id');

const now = (strFormat?: string) => {
	if (!strFormat) return new Date();
	return moment().format(strFormat);
};

const start = (date: Date): Date => {
	date.setHours(0, 0, 0, 0);
	return date;
};

const end = (date: Date): Date => {
	date.setHours(23, 59, 0, 0);
	return date;
};

const format = (date: Date | string | undefined, strFormat: string) => {
	return moment(date).format(strFormat);
};

const calcAge = (dob: Date): number => {
	//calculate month difference from current date in time
	const monthDiff = Date.now() - dob.getTime();

	//convert the calculated difference in date format
	const ageDate = new Date(monthDiff);

	//extract year from date
	const year = ageDate.getUTCFullYear();

	//now calculate the age of the user
	const age = Math.abs(year - 1970);

	return age;
};

const dateDiff = (dateStart: Date, dateEnd: Date): number => {
	var a = moment(dateStart);
	var b = moment(dateEnd);
	return a.diff(b, 'days');
};

export default { now, start, end, format, calcAge, dateDiff };

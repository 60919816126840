import Axios from 'axios';
import storage from '../utils/storage';

const axios = Axios.create({
	baseURL: process.env.REACT_APP_API_BASE_URL,
	headers: {
		Accept: 'application/json',
		'Content-Type': 'application/json',
		'X-Requested-With': 'XMLHttpRequest',
		// 'Access-Control-Allow-Origin': process.env.REACT_APP_BASE_URL!,
	},
	withCredentials: true,
	timeout: 10000,
});

// request interceptor to add token to request headers
axios.interceptors.request.use(
	async (config) => {
		// const accessToken = storage.get('access_token');

		// if (accessToken) {
		// 	config.headers = {
		// 		authorization: `Bearer ${accessToken}`,
		// 	};
		// }

		return config;
	},
	(error) => Promise.reject(error),
);

// response interceptor intercepting 401 responses, refreshing token and retrying the request
axios.interceptors.response.use(
	(response) => response,
	async (error) => {
		const originalRequest = error.config;

		// Prevent infinite loops
		if (
			(error.response.status === 403 || originalRequest.url === '/auth/refresh-token') &&
			originalRequest._retry
		) {
			storage.remove('user');
			window.location.replace('/login');
			return Promise.reject(error);
		}

		if (error.response.status === 401) {
			originalRequest._retry = true;

			return new Promise((resolve, reject) => {
				axios
					.get('/auth/refresh-token')
					.then(() => resolve(axios(originalRequest)))
					.catch((err) => {
						storage.remove('user');
						window.location.replace('/login');
						reject(err);
					});
			});
		}

		return Promise.reject(error);
	},
);

export default axios;

const set = (key: string, value: any) => {
	if (value) localStorage.setItem(key, JSON.stringify(value));
};

const get = (key: string) => {
	const value = localStorage.getItem(key);
	let result = undefined;

	if (value) {
		try {
			result = JSON.parse(value);
		} catch (error) {
			result = value;
		}
	}

	return result;
};

const remove = (key: string) => localStorage.removeItem(key);

const clear = () => localStorage.clear();

export default {
	set,
	get,
	remove,
	clear,
};

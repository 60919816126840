import React, { createContext, FC, ReactNode, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { IUser } from '../interfaces/user';
import storage from '../utils/storage';

export interface IAuthContextProps {
	isLogin: boolean;
	setIsLogin(...args: unknown[]): unknown;
	user: string;
	setUser(...args: unknown[]): unknown;
	userData: Partial<IUser>;
}
const AuthContext = createContext<IAuthContextProps>({} as IAuthContextProps);

interface IAuthContextProviderProps {
	children: ReactNode;
}
export const AuthContextProvider: FC<IAuthContextProviderProps> = ({ children }) => {
	const [isLogin, setIsLogin] = useState<boolean>(storage.get('isLogin'));
	const [user, setUser] = useState(storage.get('user'));
	const [userData, setUserData] = useState<Partial<IUser>>({});

	useEffect(() => {
		storage.set('user', user);
	}, [user]);

	useEffect(() => {
		if (user) {
			setUserData(user);
		} else {
			setUserData({});
		}
	}, [user]);

	const value = useMemo(
		() => ({
			isLogin,
			setIsLogin,
			user,
			setUser,
			userData,
		}),
		[isLogin, user, userData],
	);

	return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
AuthContextProvider.propTypes = {
	children: PropTypes.node.isRequired,
};

export default AuthContext;

import BaseService from './base';
import { ILoginPayload } from '../interfaces/user';

class AuthService extends BaseService {
	async signin(data: ILoginPayload) {
		try {
			const response = await this.instance.post('/auth/signin?platform=web', data);
			return await Promise.resolve(response.data);
		} catch (error: any) {
			return Promise.reject(error.response.data);
		}
	}

	async signout() {
		try {
			const response = await this.instance.delete('/auth/signout');
			return await Promise.resolve(response.data);
		} catch (error: any) {
			return Promise.reject(error.response.data);
		}
	}

	async me() {
		try {
			const response = await this.instance.get('/auth/me');
			return await Promise.resolve(response.data);
		} catch (error: any) {
			return Promise.reject(error.response.data);
		}
	}
}

export default AuthService;

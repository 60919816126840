import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import { useFormik } from 'formik';
import { useMutation } from '@tanstack/react-query';

import PageWrapper from '../../layout/PageWrapper/PageWrapper';
import Page from '../../layout/Page/Page';
import Card, { CardBody } from '../../components/bootstrap/Card';
import FormGroup from '../../components/bootstrap/forms/FormGroup';
import Input from '../../components/bootstrap/forms/Input';
import Button from '../../components/bootstrap/Button';
import Logo from '../../components/Logo';
import useDarkMode from '../../hooks/useDarkMode';
import AuthContext from '../../contexts/authContext';
import Spinner from '../../components/bootstrap/Spinner';
import Alert from '../../components/bootstrap/Alert';
import { storage } from '../../utils';
import { AuthService } from '../../services';
import { ILoginPayload } from '../../interfaces/user';

const authService = new AuthService();

const Login = () => {
	const navigate = useNavigate();
	const { darkModeStatus } = useDarkMode();
	const { setUser, isLogin, setIsLogin } = useContext(AuthContext);

	const formik = useFormik({
		enableReinitialize: true,
		initialValues: {
			email: '',
			password: '',
		},
		validate: (values) => {
			const errors: { email?: string; password?: string } = {};

			if (!values.email) {
				errors.email = 'Alamat email wajib di isi';
			} else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
				errors.email = 'Alamat email tidak valid';
			}

			if (!values.password) {
				errors.password = 'Silahkan masukan password';
			}

			return errors;
		},
		validateOnChange: false,
		onSubmit: (values) => loginHandler(values),
	});

	const {
		mutate: loginHandler,
		isLoading,
		error,
	} = useMutation((data: ILoginPayload) => authService.signin(data), {
		onSuccess: (res) => {
			const { user, access_token } = res;
			if (user) {
				storage.set('isLogin', true);
				storage.set('access_token', access_token);
				setIsLogin(true);
				setUser(user);
			}
		},
		onError: (err: any) => err,
	});

	useEffect(() => {
		if (isLogin) navigate('/');
	}, [navigate, isLogin]);

	return (
		<PageWrapper
			isProtected={false}
			title='Login'
			className={!darkModeStatus ? 'bg-mastani' : 'bg-dark'}>
			<Page className='p-0'>
				<div className='row h-100 align-items-center justify-content-center'>
					<div className='col-xl-4 col-lg-6 col-md-8 shadow-3d-container'>
						<Card className='shadow-3d-dark' data-tour='login-page'>
							<CardBody>
								<div className='text-center my-5'>
									<Logo width={200} height={80} />
								</div>

								<div className='text-center h1 fw-bold mt-5'>Welcome,</div>
								<div className='text-center h4 text-muted mb-5'>
									Sign in to continue!
								</div>

								{error && (
									<Alert isLight icon='Lock' isDismissible>
										<div className='row'>
											<div className='col-12'>{error.message}</div>
										</div>
									</Alert>
								)}
								<form className='row g-4' onSubmit={formik.handleSubmit}>
									<div className='col-12'>
										<FormGroup id='email' isFloating label='Your email'>
											<Input
												autoComplete='email'
												value={formik.values.email}
												isTouched={formik.touched.email}
												invalidFeedback={formik.errors.email}
												isValid={formik.isValid}
												onChange={formik.handleChange}
												onBlur={formik.handleBlur}
												onFocus={() => {
													formik.setErrors({});
												}}
											/>
										</FormGroup>
									</div>
									<div className='col-12'>
										<FormGroup id='password' isFloating label='Password'>
											<Input
												type='password'
												autoComplete='current-password'
												value={formik.values.password}
												isTouched={formik.touched.password}
												invalidFeedback={formik.errors.password}
												validFeedback='Looks good!'
												isValid={formik.isValid}
												onChange={formik.handleChange}
												onBlur={formik.handleBlur}
											/>
										</FormGroup>
									</div>
									<div className='col-12'>
										<Button
											type='submit'
											color='warning'
											className='w-100 py-3'>
											{isLoading && <Spinner isSmall inButton isGrow />} Login
										</Button>
									</div>

									{/* BEGIN :: Social Login */}
									<div className='col-12 mt-3 text-center text-muted'>OR</div>
									<div className='col-12'>
										<Button
											isOutline
											color={darkModeStatus ? 'light' : 'dark'}
											className={classNames('w-100 py-3', {
												'border-light': !darkModeStatus,
												'border-dark': darkModeStatus,
											})}
											icon='CustomGoogle'
											onClick={() => {}}>
											Continue with Google
										</Button>
									</div>
									{/* END :: Social Login */}
								</form>
							</CardBody>
						</Card>
						<div className='text-center'>
							<a href='#!' className='text-decoration-none me-3 link-light'>
								Privacy policy
							</a>
							<a href='#!' className='text-decoration-none link-light'>
								Terms of use
							</a>
						</div>
					</div>
				</div>
			</Page>
		</PageWrapper>
	);
};

export default Login;

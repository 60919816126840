import BaseService from './base';
import { IPagination } from '../interfaces/pagination';
import { IGroundObservation, IIrrigation, ILandSurvey } from '../interfaces/landSurvey';

class LandSurveyService extends BaseService {
	async create(data: any) {
		try {
			const response = await this.instance.post('/land-surveys/', data);
			return await Promise.resolve(response.data);
		} catch (error: any) {
			return Promise.reject(error.response.data);
		}
	}

	async getAll(params: IPagination) {
		try {
			const response = await this.instance.get(
				`/land-surveys?page=${params.page}&limit=${params.limit}&field=${params.field}&search=${params.search}`,
			);
			return await Promise.resolve(response.data);
		} catch (error: any) {
			return Promise.reject(error.response.data);
		}
	}

	async getById(id: string): Promise<ILandSurvey> {
		try {
			const response = await this.instance.get(`/land-surveys/${id}`);
			return await Promise.resolve(response.data);
		} catch (error: any) {
			return Promise.reject(error.response.data);
		}
	}

	async update(id: string, data: any) {
		try {
			const response = await this.instance.put(`/land-surveys/${id}`, data);
			return await Promise.resolve(response.data);
		} catch (error: any) {
			return Promise.reject(error.response.data);
		}
	}

	async verify(id: string, status: 'PENDING' | 'APPROVED' | 'REJECTED') {
		try {
			const response = await this.instance.put(`/land-surveys/${id}/verify`, { status });
			return await Promise.resolve(response.data);
		} catch (error: any) {
			return Promise.reject(error.response.data);
		}
	}

	async delete(id: string) {
		try {
			const response = await this.instance.delete(`/land-surveys/${id}`);
			return await Promise.resolve(response.data);
		} catch (error: any) {
			return Promise.reject(error.response.data);
		}
	}

	getGroundObs(_id: string, data: IGroundObservation[] | undefined): string {
		const found = data?.find((go) => go.id === _id);
		if (!found) return '-';

		const translate = {
			LOW: 'Rendah',
			MEDIUM: 'Sedang',
			HIGH: 'Tinggi',
			ACID: 'Asam',
			ALKALI: 'Basa',
			NEUTRAL: 'Netral',
			NORMAL: 'Tanah Normal',
			SALIN: 'Tanah Salin',
			CLAY: 'Tanah Liat',
			SANDY: 'Tanah Berpasir',
		};

		if (_id === 'TEXTURE') return translate[found.condition];
		if (_id === 'MUD_DEPTH') return `${found.score.toString()} CM`;
		if (_id === 'REDOKS') return found.condition;
		return `${translate[found.condition]} (${found.score})`;
	}

	getIrrigations(_id: string, data: IIrrigation[] | undefined): string {
		const found = data?.find((ir) => ir.id === _id);
		if (!found) return '-';

		const translate = {
			'VERY-GOOD': 'Sangat Baik',
			GOOD: 'Baik',
			'NOT-ENOUGH': 'Kurang Baik',
			BAD: 'Buruk',
			ACID: 'Asam',
			ALKALI: 'Basa',
			NEUTRAL: 'Netral',
			LOW: 'Rendah',
			MEDIUM: 'Sedang',
			HIGH: 'Tinggi',
		};

		if (_id === 'EC') return found.condition;
		return `${translate[found.condition]} (${found.score})`;
	}
}

export default LandSurveyService;

import BaseService from './base';
import { IPagination } from '../interfaces/pagination';
import { IUser } from '../interfaces/user';

class UserService extends BaseService {
	async create(data: any) {
		try {
			const response = await this.instance.post('/users/', data);
			return await Promise.resolve(response.data);
		} catch (error: any) {
			return Promise.reject(error.response.data);
		}
	}

	async getAll(params: IPagination) {
		try {
			const response = await this.instance.get(
				`/users?page=${params.page}&field=${params.field}&search=${params.search}`,
			);
			return await Promise.resolve(response.data);
		} catch (error: any) {
			return Promise.reject(error.response.data);
		}
	}

	async getById(id: string): Promise<IUser> {
		try {
			const response = await this.instance.get(`/users/${id}`);
			return await Promise.resolve(response.data);
		} catch (error: any) {
			return Promise.reject(error.response.data);
		}
	}

	async update(id: string, data: any) {
		try {
			const response = await this.instance.put(`/users/${id}`, data);
			return await Promise.resolve(response.data);
		} catch (error: any) {
			return Promise.reject(error.response.data);
		}
	}

	async updateSetting(id: string, data: any) {
		try {
			const response = await this.instance.put(`/users/update-setting/${id}`, data);
			return await Promise.resolve(response.data);
		} catch (error: any) {
			return Promise.reject(error.response.data);
		}
	}

	async updatePasword(id: string, data: any) {
		try {
			const response = await this.instance.put(`/users/update-password/${id}`, data);
			return await Promise.resolve(response.data);
		} catch (error: any) {
			return Promise.reject(error.response.data);
		}
	}

	async roles() {
		try {
			const response = await this.instance.get(`/roles`);
			return await Promise.resolve(response.data);
		} catch (error: any) {
			return Promise.reject(error.response.data);
		}
	}
}

export default UserService;

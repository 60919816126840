import BaseService from './base';
import { IPagination } from '../interfaces/pagination';
import { IObservation } from '../interfaces/observation';

class ObservationService extends BaseService {
	async create(data: any) {
		try {
			const response = await this.instance.post('/observations/', data);
			return await Promise.resolve(response.data);
		} catch (error: any) {
			return Promise.reject(error.response.data);
		}
	}

	async getAll(params: IPagination) {
		try {
			const response = await this.instance.get(
				`/observations?page=${params.page}&limit=${params.limit}&field=${params.field}&search=${params.search}`,
			);
			return await Promise.resolve(response.data);
		} catch (error: any) {
			return Promise.reject(error.response.data);
		}
	}

	async getById(id: string): Promise<IObservation> {
		try {
			const response = await this.instance.get(`/observations/${id}`);
			return await Promise.resolve(response.data);
		} catch (error: any) {
			return Promise.reject(error.response.data);
		}
	}

	async update(id: string, data: any) {
		try {
			const response = await this.instance.put(`/observations/${id}`, data);
			return await Promise.resolve(response.data);
		} catch (error: any) {
			return Promise.reject(error.response.data);
		}
	}

	async verify(id: string, status: 'PENDING' | 'APPROVED' | 'REJECTED') {
		try {
			const response = await this.instance.put(`/observations/${id}/verify`, { status });
			return await Promise.resolve(response.data);
		} catch (error: any) {
			return Promise.reject(error.response.data);
		}
	}

	async delete(id: string) {
		try {
			const response = await this.instance.delete(`/observations/${id}`);
			return await Promise.resolve(response.data);
		} catch (error: any) {
			return Promise.reject(error.response.data);
		}
	}

	async feedback(id: string, data: any) {
		try {
			const response = await this.instance.post(`/observations/${id}/feedback`, data);
			return await Promise.resolve(response.data);
		} catch (error: any) {
			return Promise.reject(error.response.data);
		}
	}
}

export default ObservationService;

import React, { lazy } from 'react';
import {
	authPages,
	dashboardPages,
	dataPages,
	activityPages,
	settingPages,
	errorPages,
} from '../menu';
import Login from '../pages/login';

const LANDING = {
	DASHBOARD: lazy(() => import('../pages/dashboard')),
};

const ERROR = {
	PAGE_NOT_FOUND: lazy(() => import('../pages/error/not-found')),
};

const PAGE_DATAS = {
	AGRONOMIS: lazy(() => import('../pages/agronomis')),
	AGRONOMIS_DETAIL: lazy(() => import('../pages/agronomis/detail')),
	AGRONOMIS_CREATE: lazy(() => import('../pages/agronomis/create')),
	FARMER: lazy(() => import('../pages/farmers')),
	FARMER_CREATE: lazy(() => import('../pages/farmers/create')),
	FARMER_DETAIL: lazy(() => import('../pages/farmers/detail')),
	FARMER_LAND: lazy(() => import('../pages/farmer-lands')),
	FARMER_LAND_DETAIL: lazy(() => import('../pages/farmer-lands/detail')),
	LAND_SURVEY: lazy(() => import('../pages/land-surveys')),
	LAND_SURVEY_DETAIL: lazy(() => import('../pages/land-surveys/detail')),
};

const PAGE_ACTIVITIES = {
	OBSERVATION: lazy(() => import('../pages/observations')),
};

const PAGE_SETTINGS = {
	USERS: lazy(() => import('../pages/users')),
	USERS_DETAIL: lazy(() => import('../pages/users/detail')),
	USERS_CREATE: lazy(() => import('../pages/users/create')),
};

interface IRoutes {
	path: any;
	element: JSX.Element;
	exact: boolean;
}

const getRoutes = (role: number) => {
	let routeAccess: IRoutes[] = [
		{
			path: authPages.login.path,
			element: <Login />,
			exact: true,
		},
		{
			path: dashboardPages.dashboard.path,
			element: <LANDING.DASHBOARD />,
			exact: true,
		},
		{
			path: dataPages.agronomis.path,
			element: <PAGE_DATAS.AGRONOMIS />,
			exact: true,
		},
		{
			path: `${dataPages.agronomis.path}/:id`,
			element: <PAGE_DATAS.AGRONOMIS_DETAIL />,
			exact: true,
		},
		{
			path: `${dataPages.agronomis.path}/create`,
			element: <PAGE_DATAS.AGRONOMIS_CREATE />,
			exact: true,
		},
		{
			path: dataPages.farmer.path,
			element: <PAGE_DATAS.FARMER />,
			exact: true,
		},
		{
			path: `${dataPages.farmer.path}/create`,
			element: <PAGE_DATAS.FARMER_CREATE />,
			exact: true,
		},
		{
			path: `${dataPages.farmer.path}/:id`,
			element: <PAGE_DATAS.FARMER_DETAIL />,
			exact: true,
		},
		{
			path: dataPages.farmerLand.path,
			element: <PAGE_DATAS.FARMER_LAND />,
			exact: true,
		},
		{
			path: `${dataPages.farmerLand.path}/:id`,
			element: <PAGE_DATAS.FARMER_LAND_DETAIL />,
			exact: true,
		},
		{
			path: dataPages.landSurvey.path,
			element: <PAGE_DATAS.LAND_SURVEY />,
			exact: true,
		},
		{
			path: `${dataPages.landSurvey.path}/:id`,
			element: <PAGE_DATAS.LAND_SURVEY_DETAIL />,
			exact: true,
		},
		{
			path: activityPages.observations.path,
			element: <PAGE_ACTIVITIES.OBSERVATION />,
			exact: true,
		},
		{
			path: errorPages.pageNotFound.path,
			element: <ERROR.PAGE_NOT_FOUND />,
			exact: true,
		},
	];

	if (role === 1) {
		routeAccess = [
			...routeAccess,
			{
				path: settingPages.user.path,
				element: <PAGE_SETTINGS.USERS />,
				exact: true,
			},
			{
				path: `${settingPages.user.path}/:id`,
				element: <PAGE_SETTINGS.USERS_DETAIL />,
				exact: true,
			},
			{
				path: `${settingPages.user.path}/create`,
				element: <PAGE_SETTINGS.USERS_CREATE />,
				exact: true,
			},
		];
	}

	return routeAccess;
};

export default getRoutes;

import BaseService from './base';

export interface IProvincePayload {
	id: string;
	code: string;
	name: string;
}

export interface ICityPayload {
	id: string;
	province_id: string;
	code: string;
	name: string;
}

export interface IDistrictPayload {
	id: string;
	city_id: string;
	code: string;
	name: string;
}

export interface IVillagePayload {
	id: string;
	district_id: string;
	code: string;
	name: string;
}

class RegionService extends BaseService {
	async province(): Promise<IProvincePayload[]> {
		try {
			const response = await this.instance.get('/regions/provinces');
			return await Promise.resolve(response.data);
		} catch (error: any) {
			return Promise.reject(error.response.data);
		}
	}

	async cities(provinceId: string): Promise<ICityPayload[]> {
		try {
			const response = await this.instance.get(`/regions/cities?province=${provinceId}`);
			return await Promise.resolve(response.data);
		} catch (error: any) {
			return Promise.reject(error.response.data);
		}
	}

	async districts(cityId: string): Promise<IDistrictPayload[]> {
		try {
			const response = await this.instance.get(`/regions/districts?city=${cityId}`);
			return await Promise.resolve(response.data);
		} catch (error: any) {
			return Promise.reject(error.response.data);
		}
	}

	async villages(districtId: string): Promise<IVillagePayload[]> {
		try {
			const response = await this.instance.get(`/regions/villages?district=${districtId}`);
			return await Promise.resolve(response.data);
		} catch (error: any) {
			return Promise.reject(error.response.data);
		}
	}
}

export default RegionService;

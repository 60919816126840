import confirm from './confirm';
import storage from './storage';
import time from './time';
import * as onesignal from './onesignal';
import * as planning from './planning';

const approvalStatus = (status: null | 'PENDING' | 'APPROVED' | 'REJECTED' | undefined) => {
	switch (status) {
		case null:
			return 'Dijadwalkan';

		case 'PENDING':
			return 'Menunggu Persetujuan';

		case 'APPROVED':
			return 'Disetujui';

		case 'REJECTED':
			return 'Ditolak';

		default:
			return '-';
	}
};

const fullname = (data: any): string => {
	return `${data?.firstname} ${data?.lastname}`;
};

export { confirm, storage, time, approvalStatus, onesignal, planning, fullname };

import BaseService from './base';
import { ILender } from '../interfaces/lender';

class LenderService extends BaseService {
	async create(data: any) {
		try {
			const response = await this.instance.post('/lenders/', data);
			return await Promise.resolve(response.data);
		} catch (error: any) {
			return Promise.reject(error.response.data);
		}
	}

	async getAll() {
		try {
			const response = await this.instance.get(`/lenders`);
			return await Promise.resolve(response.data);
		} catch (error: any) {
			return Promise.reject(error.response.data);
		}
	}

	async getById(id: string): Promise<ILender> {
		try {
			const response = await this.instance.get(`/lenders/${id}`);
			return await Promise.resolve(response.data);
		} catch (error: any) {
			return Promise.reject(error.response.data);
		}
	}

	async update(id: string, data: any) {
		try {
			const response = await this.instance.put(`/lenders/${id}`, data);
			return await Promise.resolve(response.data);
		} catch (error: any) {
			return Promise.reject(error.response.data);
		}
	}
}

export default LenderService;

import { AxiosInstance } from 'axios';
import axios from './axios';

export default class BaseService {
	public instance: AxiosInstance;

	constructor(instance = axios) {
		this.instance = instance;
	}
}

export const authPages = {
	login: {
		id: 'login',
		text: 'Login',
		path: '/login',
		icon: 'Login',
	},
	forgotPassword: {
		id: 'forgotPassword',
		text: 'Lupa Kata Sandi',
		path: '/forgot-password',
		icon: 'Password',
	},
};

export const errorPages = {
	pageNotFound: {
		id: 'pageNotFound',
		text: 'pageNotFound',
		path: '/error/404',
		icon: 'Page Not Found',
	},
};

export const dashboardPages = {
	dashboard: {
		id: 'dashboard',
		text: 'Dashboard',
		path: '/',
		icon: 'Dashboard',
		subMenu: null,
	},
};

export const dataPages = {
	data: {
		id: 'data',
		text: 'Data',
		icon: 'Extension',
	},
	agronomis: {
		id: 'agronomis',
		text: 'Agronomis',
		path: '/agronomis',
		icon: 'PersonSearch',
	},
	farmer: {
		id: 'farmer',
		text: 'Petani',
		path: '/farmers',
		icon: 'Groups',
	},
	farmerLand: {
		id: 'farmerLand',
		text: 'Lahan Petani',
		path: '/farmer-lands',
		icon: 'Dashboard',
	},
	landSurvey: {
		id: 'landSurvey',
		text: 'Survey Lahan',
		path: '/land-surveys',
		icon: 'Task',
	},
};

export const activityPages = {
	activity: {
		id: 'report',
		text: 'Logger',
		icon: 'Timeline',
	},
	observations: {
		id: 'observations',
		text: 'Pengamatan',
		path: '/observations',
		icon: 'EventNote',
	},
	// sprayings: {
	// 	id: 'sprayings',
	// 	text: 'Penyemprotan',
	// 	path: '/sprayings',
	// 	icon: 'Sanitizer',
	// },
	// fertilizations: {
	// 	id: 'fertilizations',
	// 	text: 'Pemupukan',
	// 	path: '/fertilizations',
	// 	icon: 'Verified',
	// },
	// harvests: {
	// 	id: 'harvests',
	// 	text: 'Panen',
	// 	path: '/harvests',
	// 	icon: 'Yard',
	// },
};

export const masterPages = {
	master: {
		id: 'master',
		text: 'Data Master',
		icon: 'Extension',
	},
	// capitalPlan: {
	// 	id: 'capitalPlan',
	// 	text: 'Rencana Modal',
	// 	path: '/capital-plans',
	// 	icon: 'List',
	// },
	// prevCapital: {
	// 	id: 'prevCapital',
	// 	text: 'Modal Sebeumnya',
	// 	path: '/prev-capitals',
	// 	icon: 'List',
	// },
};

export const reportPages = {
	report: {
		id: 'report',
		text: 'Laporan',
		icon: 'Extension',
	},
	surveyReport: {
		id: 'surveyReport',
		text: 'Hasil Survey',
		path: '/surveyReport',
		icon: 'Summarize',
	},
};

export const settingPages = {
	setting: {
		id: 'setting',
		text: 'Pengaturan',
		icon: 'Extension',
	},
	user: {
		id: 'user',
		text: 'Manajemen User',
		path: '/users',
		icon: 'ManageAccounts',
	},
};

interface IMenu {
	authPages?: any;
	dashboardPages?: any;
	errorPages?: any;
	dataPages?: any;
	activityPages?: any;
	masterPages?: any;
	reportPages?: any;
	settingPages?: any;
}

const getMenu = (role: number) => {
	let menuAccess: IMenu = {
		authPages,
		dashboardPages,
		errorPages,
		dataPages,
		activityPages,
	};

	if (role === 1) {
		// administrator
		menuAccess.reportPages = reportPages;
		// menuAccess.masterPages = masterPages;
		menuAccess.settingPages = settingPages;
	} else if (role === 3) {
		// admin HO
		menuAccess.reportPages = reportPages;
	} else if (role === 4) {
		// lender
		menuAccess.dataPages = {
			data: dataPages.data,
			farmer: dataPages.farmer,
			farmerLand: dataPages.farmerLand,
			landSurvey: dataPages.landSurvey,
		};
	}

	return menuAccess;
};

export default getMenu;

import React from 'react';
import { authPages, dashboardPages, dataPages, activityPages } from '../menu';
import DefaultHeader from '../pages/common/Headers/DefaultHeader';

const headers = [
	{ path: authPages.login.path, element: null, exact: true },
	{ path: dashboardPages.dashboard.path, element: <DefaultHeader />, exact: true },
	{
		path: dataPages.agronomis.path,
		element: <DefaultHeader title='Agronomis' />,
		exact: true,
	},
	{
		path: `${dataPages.agronomis.path}/:id`,
		element: <DefaultHeader title='Profile Agronomis' />,
		exact: true,
	},
	{
		path: dataPages.farmer.path,
		element: <DefaultHeader title='Petani' />,
		exact: true,
	},
	{
		path: dataPages.farmerLand.path,
		element: <DefaultHeader title='Lahan Petani' />,
		exact: true,
	},
	{
		path: `${dataPages.farmerLand.path}/:id`,
		element: <DefaultHeader title='Detail Lahan' />,
		exact: true,
	},
	{
		path: dataPages.landSurvey.path,
		element: <DefaultHeader title='Survey Lahan' />,
		exact: true,
	},
	{
		path: `${dataPages.landSurvey.path}/:id`,
		element: <DefaultHeader title='Detail Survey Lahan' />,
		exact: true,
	},
	{
		path: `${dataPages.farmer.path}/:id`,
		element: <DefaultHeader title='Profile Petani' />,
		exact: true,
	},
	{
		path: activityPages.observations.path,
		element: <DefaultHeader title='Pengamatan' />,
		exact: true,
	},
	{
		path: `*`,
		element: <DefaultHeader />,
	},
];

export default headers;

import BaseService from './base';
import { IPagination } from '../interfaces/pagination';
import { IFarmerLand } from '../interfaces/farmerLand';
import { ILandSurvey } from '../interfaces/landSurvey';

class FarmerLandService extends BaseService {
	async create(data: any) {
		try {
			const response = await this.instance.post('/farmer-lands/', data);
			return await Promise.resolve(response.data);
		} catch (error: any) {
			return Promise.reject(error.response.data);
		}
	}

	async getAll(params: IPagination) {
		try {
			const response = await this.instance.get(
				`/farmer-lands?page=${params.page}&limit=${params.limit}&field=${params.field}&search=${params.search}`,
			);
			return await Promise.resolve(response.data);
		} catch (error: any) {
			return Promise.reject(error.response.data);
		}
	}

	async getById(id: string): Promise<IFarmerLand> {
		try {
			const response = await this.instance.get(`/farmer-lands/${id}`);
			return await Promise.resolve(response.data);
		} catch (error: any) {
			return Promise.reject(error.response.data);
		}
	}

	async update(id: string, data: any) {
		try {
			const response = await this.instance.put(`/farmer-lands/${id}`, data);
			return await Promise.resolve(response.data);
		} catch (error: any) {
			return Promise.reject(error.response.data);
		}
	}

	async verify(id: string, status: 'PENDING' | 'APPROVED' | 'REJECTED') {
		try {
			const response = await this.instance.put(`/farmer-lands/${id}/verify`, { status });
			return await Promise.resolve(response.data);
		} catch (error: any) {
			return Promise.reject(error.response.data);
		}
	}

	async delete(id: string) {
		try {
			const response = await this.instance.delete(`/farmer-lands/${id}`);
			return await Promise.resolve(response.data);
		} catch (error: any) {
			return Promise.reject(error.response.data);
		}
	}

	async getSurvey(id: string): Promise<ILandSurvey> {
		try {
			const response = await this.instance.get(`/farmer-lands/${id}/land-surveys`);
			return await Promise.resolve(response.data);
		} catch (error: any) {
			return Promise.reject(error.response.data);
		}
	}

	async updatePlanning(farmerLandId: string, data: any) {
		try {
			const response = await this.instance.post(
				`/farmer-lands/${farmerLandId}/planning`,
				data,
			);
			return await Promise.resolve(response.data);
		} catch (error: any) {
			return Promise.reject(error.response.data);
		}
	}

	async assignAgronomis(id: string, data: any) {
		try {
			const response = await this.instance.put(`/farmer-lands/${id}/agronomis`, data);
			return await Promise.resolve(response.data);
		} catch (error: any) {
			return Promise.reject(error.response.data);
		}
	}

	async updateLender(id: string, data: any) {
		try {
			const response = await this.instance.put(`/farmer-lands/${id}/lender`, data);
			return await Promise.resolve(response.data);
		} catch (error: any) {
			return Promise.reject(error.response.data);
		}
	}

	async getSchedule(id: string): Promise<any[]> {
		try {
			const response = await this.instance.get(`/farmer-lands/${id}/schedules`);
			return await Promise.resolve(response.data);
		} catch (error: any) {
			return Promise.reject(error.response.data);
		}
	}
}

export default FarmerLandService;

import BaseService from './base';
import { IPagination } from '../interfaces/pagination';
import { IFarmer } from '../interfaces/farmer';

class FarmerService extends BaseService {
	async create(data: any) {
		try {
			const response = await this.instance.post('/farmers/', data);
			return await Promise.resolve(response.data);
		} catch (error: any) {
			return Promise.reject(error.response.data);
		}
	}

	async getAll(params: IPagination) {
		try {
			const response = await this.instance.get(
				`/farmers?page=${params.page}&limit=${params.limit}&field=${params.field}&search=${params.search}`,
			);
			return await Promise.resolve(response.data);
		} catch (error: any) {
			return Promise.reject(error.response.data);
		}
	}

	async getById(id: string): Promise<IFarmer> {
		try {
			const response = await this.instance.get(`/farmers/${id}`);
			return await Promise.resolve(response.data);
		} catch (error: any) {
			return Promise.reject(error.response.data);
		}
	}

	async update(id: string, data: any) {
		try {
			const response = await this.instance.put(`/farmers/${id}`, data);
			return await Promise.resolve(response.data);
		} catch (error: any) {
			return Promise.reject(error.response.data);
		}
	}

	async getFarmerLand(id: string, page: number = 1) {
		try {
			const response = await this.instance.get(`/farmers/${id}/farmer-lands?page=${page}`);
			return await Promise.resolve(response.data);
		} catch (error: any) {
			return Promise.reject(error.response.data);
		}
	}

	async assignAgronomis(id: string, data: any) {
		try {
			const response = await this.instance.put(`/farmers/${id}/agronomis`, data);
			return await Promise.resolve(response.data);
		} catch (error: any) {
			return Promise.reject(error.response.data);
		}
	}
}

export default FarmerService;
